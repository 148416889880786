import css from 'styled-jsx/css';
import { DEVICE_WIDTH_BREAKPOINT } from '~/app/lib/device/constants';

export const globalStyle = css.global`
  html,
  body {
    height: 100%;
    width: 100%;

    /* let <Scroller> handle all scrolling */
    overflow: hidden;

    margin: 0;
    padding: 0;
    font-size: 17px;
  }

  #__next {
    height: 100%;
  }

  * {
    box-sizing: border-box;
  }

  html {
    /* prevent safari changing the font-sizes */
    -webkit-text-size-adjust: 100%;

    /* system font */
    font-family: -apple-system, BlinkMacSystemFont, 'Roboto', 'Oxygen', 'Ubuntu',
      'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;

    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-font-smoothing: antialiased;

    /* 1rem */
    /* base font-size for small phones (eg. iphone SE) */
    font-size: 10px;

    font-weight: 300;
    letter-spacing: 0.03em;
  }

  /* base font-size for medium/average phones (eg. iphone 13) */
  @media (min-width: 390px) {
    html {
      /* 1rem */
      font-size: 10.5px;
    }
  }

  /* base font-size large mobile screens (eg. iphone max) */
  @media (min-width: 420px) {
    html {
      /* 1rem */
      font-size: 10.8px;
    }
  }

  /* base font-size 113% on large-screen (eg. ipad -> macbook pro) */
  @media (min-width: ${DEVICE_WIDTH_BREAKPOINT}px) {
    html {
      /* 1rem */
      font-size: 11.4px;
    }
  }

  /* base font-size 125% on xl-screens (eg. large monitors) */
  @media (min-width: 1600px) {
    html {
      /* 1rem */
      font-size: 12.5px;
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    margin: 0;
    font: inherit;
  }

  :focus {
    outline: 0;
  }

  ::-moz-focus-inner {
    border: 0;
  }

  ::selection {
    background-color: rgba(255, 255, 255, 1);
    color: #000;
  }

  svg,
  img {
    display: block;
  }

  [role='button'],
  a {
    text-decoration: none;
    color: inherit;
    cursor: pointer;
  }

  ul {
    padding: 0;
    margin: 0;
  }

  li {
    list-style-type: none;
  }

  b {
    font-weight: bold;
  }
`;

// TODO: move this into styles and make dynamic props inline
export const appStyle = css.resolve`
  .app {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .banner {
    flex-shrink: 0;
  }

  .content {
    flex-grow: 1;
    overflow: hidden;
    contain: strict;
  }

  .drawer {
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    z-index: 1;

    width: 31rem;
    max-width: 95%;
    contain: strict;
    visibility: hidden;

    transform: translateX(-100%);
    transition: transform 200ms;
  }

  .drawer.drawerIsOpen {
    transform: translateX(0%);
    visibility: visible;
  }

  .backdrop {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 1;

    background: rgba(255, 255, 255, 0.2);
    transition: opacity 300ms;
    pointer-events: none;
    visibility: hidden;
    opacity: 0;
  }

  .backdrop.drawerIsOpen {
    pointer-events: all;
    visibility: visible;
    opacity: 1;
  }
`;
