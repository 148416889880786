import { ItemTypes } from '~/types';

import { SelectedPrimaryOwnerAccount } from '../lib/selectors';
import { MappedCustomPage } from '../../songwhipApi/mapper';
import { OrchardBrands } from '../../songwhipApi/types';
import ApiError from '../../errors/ApiError';

export enum CustomPagesActionTypes {
  FETCH_CUSTOM_PAGE_START = 'FETCH_CUSTOM_PAGE_START',
  FETCH_CUSTOM_PAGE_SUCCESS = 'FETCH_CUSTOM_PAGE_SUCCESS',
  FETCH_CUSTOM_PAGE_ERROR = 'FETCH_CUSTOM_PAGE_ERROR',
}
export interface FetchCustomPageStartAction {
  type: CustomPagesActionTypes.FETCH_CUSTOM_PAGE_START;
  customPageId: number;
}

export interface FetchCustomPageSuccessAction {
  type: CustomPagesActionTypes.FETCH_CUSTOM_PAGE_SUCCESS;
  payload: MappedCustomPage;
}

export interface FetchCustomPageErrorAction {
  type: CustomPagesActionTypes.FETCH_CUSTOM_PAGE_ERROR;
  customPageId: number;
  error: Error;
}

export type CustomPageAction =
  | FetchCustomPageStartAction
  | FetchCustomPageSuccessAction
  | FetchCustomPageErrorAction;

export interface SelectedCustomPage {
  type: ItemTypes.CUSTOM_PAGE;
  id: number;
  artistId: number;
  artistIds: number[];
  artistName: string;
  artistPagePath: string;
  error: ApiError | Error | undefined;

  /**
   * The resolved image. Favours custom image, falling back to originalImage.
   */
  image: string | undefined;

  isLoading: boolean;
  isOwned: boolean;
  pageBrand?: OrchardBrands;
  name: string;
  pagePath: string;
  userCanEdit: boolean | undefined;
  ownedByAccountIds: number[] | undefined;
  config: MappedCustomPage['config'];
  primaryOwnerAccount: SelectedPrimaryOwnerAccount | undefined;
  customLinks: MappedCustomPage['customLinks'];
  isDraft?: boolean;

  /**
   * REVIEW: These hardcoded props are for compat with artist/album/track items.
   * Custom page doesn't have any functionality that rely on these props, but as we treat it as another item type
   * and it has almost identical set of features, we need to provide these props to avoid
   * to handle ItemTypes.CUSTOM_PAGE cases in multiple places
   * in the future we might want to completely separate custom pages from other items
   */
  isShallow: false;
  links: undefined;
  originalImage: undefined;
  updatedAtTimestamp: number | undefined;
}

export interface StoredCustomPage extends Omit<MappedCustomPage, 'artists'> {
  artistIds: number[];
}
