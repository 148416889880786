import classnames from 'classnames';

import React, {
  useCallback,
  MouseEvent,
  ReactNode,
  HTMLAttributes,
} from 'react';

import openWindow from '~/app/lib/utils/openWindow';
import navigateWindow from '~/app/lib/utils/navigateWindow';
import { QueryParams, useAppRouter } from '~/app/lib/router2';
import isExternalUrl from '~/app/lib/utils/isExternalUrl';

export type LinkClickHandlerParams = {
  event: MouseEvent<HTMLAnchorElement>;
  href?: string;
};

export type LinkClickHandler = (
  props: LinkClickHandlerParams
) => Promise<any> | void;

export interface LinkProps
  extends Pick<HTMLAttributes<HTMLAnchorElement>, 'tabIndex'> {
  href: string;
  title?: string;
  routerQuery?: QueryParams;
  className?: string;
  isUnderlined?: boolean;
  isNoFollow?: boolean;
  inNewTab?: boolean;
  targetBlank?: boolean;
  withFocusStyle?: boolean;
  withHoverStyle?: boolean;
  autoFocus?: boolean;
  style?: React.CSSProperties;
  onClick?: LinkClickHandler;
  children: ReactNode;
  testId?: string;
  noDrag?: boolean;
}

const Link: React.FC<LinkProps> = (props) => {
  const {
    href,
    routerQuery,
    children,
    onClick,
    style,
    className,
    targetBlank = true,
    isUnderlined = false,
    withFocusStyle = true,
    withHoverStyle = false,
    testId,
    noDrag,
    ...anchorProps
  } = props;

  const router = useAppRouter();

  const onClickInternal = useCallback(
    async (event: MouseEvent<HTMLAnchorElement>) => {
      // callee can optionally pass an onClick callback if they
      // want to do something before/during the navigation
      if (onClick) {
        const returnValue = onClick({
          event,
          href: event.currentTarget.href,
        });

        // if somewhere up the stack event.preventDefault() was
        // called, then we shouldn't navigate as it's assumed that
        // navigation is being handled somewhere w/ more UX context.
        if (event.defaultPrevented) return;

        // stop the browser performing it's own
        // navigation as we handle it using router
        event.preventDefault();

        // if a promise was returned wait fr it to resolve before
        // routing, this allows us to do things like closing the
        // side-nav smoothly before triggering navigation which
        // can lock up main-thread.
        if (returnValue instanceof Promise) {
          await returnValue;
        }
      } else {
        // let browser handle target=_blank behavior
        if (props.inNewTab) return;

        // stop the browser performing it's own
        // navigation as we handle it using router
        event.preventDefault();
      }

      // anchor hrefs don't run through router, instead scroll the element into view
      if (href?.[0] === '#') {
        document
          .getElementById(href.slice(1))
          ?.scrollIntoView
          // disable smooth scroll until Chrome fixes in conjunction with `content-visibility: auto`
          // { behavior: 'smooth' }
          ();

        location.hash = href;

        return;
      }

      // external links don't run through next/router
      if (isExternalUrl(href)) {
        if (targetBlank) openWindow(href);
        else navigateWindow(href);
        return;
      }

      // do the clientside navigation
      router.push(href, { routerQuery });
    },
    [href]
  );

  return (
    <a
      {...resolveLinkProps({ ...props, href })}
      {...anchorProps}
      style={style}
      data-testid={testId}
      className={classnames(className, {
        withFocusStyle,
        withHoverStyle,
        isUnderlined,
      })}
      onDragStart={noDrag ? (event) => event.preventDefault() : undefined}
      onClick={onClickInternal}
    >
      {children}
      <style jsx>{`
        a :global(*) {
          cursor: pointer;
        }

        .withHoverStyle:hover {
          text-decoration: underline;
          color: #fff;
        }

        .isUnderlined {
          display: inline-block;
          color: #888;
          border-bottom: dotted 0.1em #666;
        }

        .isUnderlined.withHoverStyle:hover {
          text-decoration: none;
          color: #fff;
        }

        /* subtle glow when focused via keyboard */
        .withFocusStyle:focus-visible {
          filter: drop-shadow(0px 1px 8px white);
           {
            /* Disabled as showing up in unwanted places. The only place we really want this is in paragraph styling. */
            /* text-decoration: underline; */
          }
        }
      `}</style>
    </a>
  );
};

const resolveLinkProps = ({
  inNewTab = false,
  isNoFollow = false,
  title = undefined,
  href,
}: LinkProps) => {
  const props = { href, title };
  const rel: string[] = [];

  if (inNewTab) {
    rel.push('noopener', 'noreferrer');
    props['target'] = '_blank';
  }

  if (isNoFollow) {
    rel.push('nofollow');
  }

  if (title) {
    props['aria-label'] = title;
  }

  const relString = rel.join(' ');
  if (relString) props['rel'] = relString;

  return props;
};

export default Link;
