exports.SHORT_HOST = 'sng.to';
exports.THEME_COLOR = '#000000';
exports.APP_TITLE = 'Songwhip';
exports.ACCOUNT_SCOPE_PARAM_PREFIX = 'account:';
exports.PATH_SCOPE_PARAM_PREFIX = 'path:';
exports.DISABLE_PAGES_EDGE_CACHE_FOR_AUTHED_USERS = false;
exports.ANALYTICS_DURATION_DAYS = 14;
exports.DEFAULT_PAGE_EDGE_CACHE_TTL = 60 * 60 * 24 * 7; // one week

// query params
exports.COUNTRY_QUERY_PARAM = 'country';
exports.DEVICE_TYPE_QUERY_PARAM = 'device';
exports.LANGUAGE_QUERY_PARAM = 'lang';
exports.SCOPE_QUERY_PARAM = 'scope';
exports.CUSTOM_DOMAIN_QUERY_PARAM = 'customDomain';
exports.BYPASS_EDGE_CACHE_PARAM = 'bypass_edge_cache';
exports.SONGWHIP_NO_CACHE_QUERY_PARAM = 'no_cache';
exports.BYPASS_COUNTRY_BLOCKING = 'bypass_country_blocking';

// cookie names
exports.COUNTRY_COOKIE_NAME = 'songwhip.country';
exports.LANGUAGE_COOKIE_NAME = 'songwhip.language';
exports.GEOLOCATION_COOKIE_NAME = 'songwhip.geolocation';
exports.DOB_COOKIE_NAME = 'songwhip.dob'; // timestamp
exports.EXCLUSIVE_CONTENT_COOKIE_NAME = 'songwhip.exclusiveContent';

// third-party
exports.SONGWHIP_FACEBOOK_URL = 'https://facebook.com/songwhip';
exports.SONGWHIP_FACEBOOK_GROUP_URL = 'https://facebook.com/groups/songwhip';
exports.SONGWHIP_TWITTER_URL = 'https://twitter.com/songwhip';
exports.SONGWHIP_INSTAGRAM_URL = 'https://instagram.com/songwhip';
exports.SONGWHIP_MESSENGER_URL = 'https://m.me/songwhip';
exports.SONGWHIP_STORE_URL = 'https://teespring.com/stores/songwhip';
exports.SONGWHIP_EMAIL = 'help@songwhip.com';
exports.FACEBOOK_PAGE_ID = '1912732388996307';
exports.FACEBOOK_APP_ID = '620945261442606';
exports.ITUNES_AFFILIATE_TOKEN = '1001l3biH';

exports.OFFLINE_PAGES = [
  '/',
  '/create',
  '/convert',
  '/contact',
  '/[param1]',
  '/[param1]/[param2]',
  '/offline',
];
